interface PaymentConfig {
  shopId: string;
  shopPass: string;
  orderId: string;
  returnUrl: string;
  cancelUrl: string;
}

declare global {
  interface Window {
    GMO_PAYMENT_CONFIG: {
      initialAmount: number;
      recurringAmount: number;
      paymentCycle: number;
      paymentTiming: number;
    };
  }
}

const GMO_PAYMENT_URL = 'https://link.mul-pay.jp/v1/plus';

export const createPaymentUrl = async ({
  shopId,
  shopPass,
  orderId,
  returnUrl,
  cancelUrl,
}: PaymentConfig): Promise<string> => {
  // Create JSON payload according to Link Type Plus v1 format
  const jsonPayload = {
    configid: "001",
    transaction: {
      OrderID: orderId,
      Amount: window.GMO_PAYMENT_CONFIG.initialAmount,
      RecurringAmount: window.GMO_PAYMENT_CONFIG.recurringAmount,
      PaymentCycle: window.GMO_PAYMENT_CONFIG.paymentCycle,
      PaymentTiming: window.GMO_PAYMENT_CONFIG.paymentTiming,
      ReturnURL: returnUrl,
      CancelURL: cancelUrl,
      JobCd: "CAPTURE",
      Method: "recurring",
      DisplayType: "pc",
      Lang: "ja",
      Tds2Type: "2"  // Force 3D Secure for testing
    }
  };
  
  console.log('Payment Configuration:', {
    initialAmount: window.GMO_PAYMENT_CONFIG.initialAmount,
    recurringAmount: window.GMO_PAYMENT_CONFIG.recurringAmount,
    paymentCycle: window.GMO_PAYMENT_CONFIG.paymentCycle,
    returnUrl,
    cancelUrl
  });

  // Convert JSON to string
  const jsonStr = JSON.stringify(jsonPayload);

  // Base64 URL-safe encode the JSON string
  const base64Params = encodeBase64Url(jsonStr);

  // Create SHA256 hash of base64 params + shop password
  const hash = await createSha256(base64Params + shopPass);

  // Combine parameters with hash
  const parameter = `${base64Params}.${hash}`;

  // Return final URL
  const finalUrl = `${GMO_PAYMENT_URL}/${shopId}/checkout/${parameter}`;
  console.log('Generated Payment URL:', finalUrl);
  return finalUrl;
};

export const generateOrderId = (): string => {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
  // Use only alphanumeric characters and hyphens as per GMO requirements
  return `order-${timestamp}-${random}`;
};

// Utility function to encode string as URL-safe base64
function encodeBase64Url(input: string): string {
  // First, encode as regular base64
  const base64 = btoa(unescape(encodeURIComponent(input)));
  // Make URL safe by replacing characters
  return base64
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

// Utility function to create SHA256 hash
async function createSha256(value: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

export const handlePaymentResponse = (searchParams: URLSearchParams): {
  success: boolean;
  message: string;
} => {
  const errCode = searchParams.get('ErrCode');
  const errInfo = searchParams.get('ErrInfo');
  
  console.log('Payment Response:', {
    errCode,
    errInfo,
    accessId: searchParams.get('AccessID'),
    allParams: Object.fromEntries(searchParams.entries())
  });

  if (errCode) {
    // Handle specific error cases
    switch (errCode) {
      case 'G65':
        return {
          success: false,
          message: 'カード番号が正しくありません。',
        };
      case 'G03':
        return {
          success: false,
          message: '限度額を超えています。',
        };
      case 'G44':
        return {
          success: false,
          message: 'セキュリティコードが正しくありません。',
        };
      case 'E91':
        return {
          success: false,
          message: 'パラメータエラーが発生しました。入力内容を確認してください。',
        };
      default:
        return {
          success: false,
          message: `決済エラーが発生しました。(${errCode}: ${errInfo})`,
        };
    }
  }

  // Check for successful payment
  const accessId = searchParams.get('AccessID');
  if (accessId) {
    return {
      success: true,
      message: '決済が完了しました。',
    };
  }

  return {
    success: false,
    message: '決済処理中にエラーが発生しました。',
  };
};
