import { useState, useEffect } from 'react'
import './App.css'
import { createPaymentUrl, generateOrderId, handlePaymentResponse } from './lib/gmoPayment'

const SHOP_ID = import.meta.env.VITE_GMO_SHOP_ID
const SHOP_PASS = import.meta.env.VITE_GMO_SHOP_PASS

function App() {
  const [isProcessing, setIsProcessing] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState<string | null>(null)

  useEffect(() => {
    // Handle payment response when returning from GMO payment screen
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('ErrCode') || searchParams.has('AccessID')) {
      const result = handlePaymentResponse(searchParams)
      setPaymentStatus(result.message)
    }
  }, [])

  const handlePayment = async () => {
    setIsProcessing(true)
    setPaymentStatus(null)

    try {
      const orderId = generateOrderId()
      const currentUrl = window.location.href.split('?')[0]
      
      const paymentUrl = await createPaymentUrl({
        shopId: SHOP_ID,
        shopPass: SHOP_PASS,
        orderId,
        returnUrl: currentUrl,
        cancelUrl: currentUrl,
      })

      // Redirect to GMO payment screen
      window.location.href = paymentUrl
    } catch (error) {
      setPaymentStatus('エラーが発生しました。もう一度お試しください。')
      console.error('Payment error:', error)
      setIsProcessing(false)
    }
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">
          クレジットカード決済
        </h1>
        
        <div className="space-y-4">
          <div className="text-center mb-4">
            <p className="text-sm text-gray-600">初回決済金額: 100円</p>
            <p className="text-sm text-gray-600">2回目以降: 300円 / 30日</p>
          </div>

          {paymentStatus && (
            <div className="mt-4 p-4 rounded-md bg-red-50 text-red-700">
              {paymentStatus}
            </div>
          )}

          <button
            onClick={handlePayment}
            disabled={isProcessing}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400"
          >
            {isProcessing ? '処理中...' : '決済する'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default App
